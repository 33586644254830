<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { t } = useI18n()

defineProps<Props>()

defineOptions({
  name: 'GenericPageCinemaSnackShop',
})

const modelValue = ref('0')
const items = ref([
  {
    label: t('tabs.snackShop'),
    value: '0',
    slot: 'tab-0',
  },
  {
    label: t('tabs.orders'),
    value: '1',
    slot: 'tab-1',
  },
])
</script>

<template>
  <PageGridColumn class="widget:pt-0 py-8">
    <KTabs v-model="modelValue" :items>
      <template #tab-0>
        <ConcessionShop :cinema />
      </template>
      <template #tab-1>
        <OnSiteConcessions :cinema />
      </template>
    </KTabs>
  </PageGridColumn>
</template>

<i18n>
de:
  tabs:
    snackShop: "Snack-Shop"
    orders: "Bestellungen"
en:
  tabs:
    snackShop: "Snack-Shop"
    orders: "Orders"
es:
  tabs:
    snackShop: "Snack-Shop"
    orders: "Pedidos"
</i18n>
